import React from 'react';
import { withRouter } from 'react-router-dom';

class NavigationPatcher extends React.Component {
  resolveRelativePath = path => {
    // Handle object-style paths (e.g. { pathname: '/contact', search: '?param=value' })
    if (typeof path === 'object' && path.pathname) {
      return {
        ...path,
        pathname: this.resolveRelativePath(path.pathname)
      };
    }

    // If it's an absolute path or external URL, return as is
    if (path.startsWith('/') || path.startsWith('http')) {
      return path;
    }

    // Get the current path and split into segments
    const currentPathSegments = window.location.pathname.split('/').filter(Boolean);
    // Remove the last segment and add our new relative path
    currentPathSegments.pop();
    currentPathSegments.push(path);

    // Reconstruct the path with leading slash
    return '/' + currentPathSegments.join('/');
  };

  shouldReloadPath = path => {
    // For object-style paths, check the pathname
    const pathToCheck = typeof path === 'object' ? path.pathname : path;
    const resolvedPath = this.resolveRelativePath(pathToCheck);

    const patterns = [/^\/apartments\/[^\/]+\/[^\/]+\/[^\/]+$/];
    return patterns.some(pattern => pattern.test(resolvedPath));
  };

  componentDidMount() {
    // Store the original version of history.push
    this.originalPush = this.props.history.push;

    // Monkey patch history.push
    this.props.history.push = (path, state) => {
      if (this.shouldReloadPath(path)) {
        const resolvedPath = this.resolveRelativePath(path);
        // If it's an object with search params, include them in the reload URL
        const finalPath = typeof resolvedPath === 'object' ? resolvedPath.pathname + (resolvedPath.search || '') : resolvedPath;
        window.location.href = finalPath;
      } else {
        this.originalPush(path, state);
      }
    };
  }

  componentWillUnmount() {
    // Restore the original history.push function when the component unmounts
    this.props.history.push = this.originalPush;
  }

  render() {
    // This component does not need to render anything
    return null;
  }
}

export default withRouter(NavigationPatcher);
